<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">组织关系</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >组织机构</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >组织机构</el-breadcrumb-item
        >
        <el-breadcrumb-item>组织关系</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">项目代码:</div>
          <el-input v-model="code" placeholder="请输入组织代码"></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">组织编码:</div>
          <el-input v-model="number" placeholder="请输入组织编码"></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">组织名称:</div>
          <el-input v-model="name" placeholder="请输入组织名称"></el-input>
        </div>

        <button class="btn-pre" @click="getOrganizations(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-add" @click="newBuilt" v-if="powers.add">
          <i class="fa ion-plus-round"></i>新建
        </button>
      </div>
      <div class="table-box">
        <el-table
          ref="doLayout"
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          row-key="TreeId"
          lazy
          :load="load"
          :tree-props="{ children: 'Children', hasChildren: 'HasChildren' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column type="" label="#" width="45">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="ID" label="组织关系id" width="80" />
          <el-table-column prop="Name" label="中文名称" width="80" />
          <el-table-column prop="NameEN" label="英文名称" width="80" />
          <el-table-column prop="NameEN" label="节点类型" width="80">
            <template slot-scope="scope">
              <div class="label-primary approval" v-if="scope.row.Para1 === 1">
                项目部
              </div>
              <div class="label-warning approval" v-if="scope.row.Para1 === 2">
                单位
              </div>
              <div class="label-info approval" v-if="scope.row.Para1 === 3">
                部门
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Number" label="编码" width="80" />
          <el-table-column prop="Abbreviation" label="缩写" width="80" />
          <el-table-column
            prop="SubjectTypeName"
            label="主体分类名称"
            width="80"
          />
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <i
                class="fa fa-edit"
                v-if="powers.update"
                @click="edit(scope.row)"
              ></i>
              <i
                class="fa fa-trash"
                v-if="powers.delete"
                @click="deleteOrganizations(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getOrganizations(1)"
          @current-change="getOrganizations"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="dialogType === 1 ? '新建组织关系' : '编辑组织关系'"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
      top="13vh"
    >
      <div class="dialog-main">
        <div class="line-box" v-if="dialogType === 2">
          <div class="from-box">
            <div class="name">中文名称:</div>
            <el-input
              v-model="parameter.Name"
              disabled
              placeholder="部门编码"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">英文名称:</div>
            <el-input
              v-model="parameter.NameEN"
              disabled
              placeholder="部门编码"
            ></el-input>
          </div>
        </div>
        <div class="line-box" v-if="dialogType === 2">
          <div class="from-box">
            <div class="name">代码:</div>
            <el-input
              v-model="parameter.Code"
              disabled
              placeholder="部门代码"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">编码:</div>
            <el-input
              v-model="parameter.Number"
              disabled
              placeholder="部门编码"
            ></el-input>
          </div>
        </div>
        <div class="line-box" v-if="dialogType === 2">
          <div class="single">
            <div class="name">缩写:</div>
            <el-input
              v-model="parameter.Abbreviation"
              disabled
              placeholder="缩写"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">组织类型:</div>
            <el-select
              v-model="parameter.OrganizationType"
              placeholder="组织类型"
              @change="changeOrganizationType"
              :disabled="dialogType === 2"
              filterable
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="from-box">
            <div class="name">组织:</div>
            <el-select
              v-model="parameter.Id"
              placeholder="请选择组织"
              :disabled="dialogType === 2"
              filterable
              clearable
            >
              <el-option
                v-for="item in options3"
                :key="
                  parameter.OrganizationType === 1 ? item.ProjectID : item.ID
                "
                :label="
                  parameter.OrganizationType === 1
                    ? item.ProjectName
                    : parameter.OrganizationType === 2
                    ? item.CompanyCHS
                    : item.DepartmentCHS
                "
                :value="
                  parameter.OrganizationType === 1 ? item.ProjectID : item.ID
                "
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line-box">
          <div class="single">
            <div class="name">上级组织:</div>
            <el-cascader
              v-model="parameter.TreeParentId"
              :options="tree"
              :props="props1"
              clearable
            ></el-cascader>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">取消</el-button
        ><el-button class="pop-save" @click="addUpRoles">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { auditRecords } from "@/utils/tableData";
import {
  getOrganizations,
  getOrganizationsChildren,
  updateOrganizations,
  getOrganizationsTree,
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  addOrganizations,
  deleteOrganizations,
} from "@/api/user";
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      auditRecords: auditRecords,
      dialogVisible: false,
      dialogType: 1, //1新建2、编辑3、查看
      options1: null,
      options2: [
        {
          value: 1,
          label: "项目部",
        },
        {
          value: 2,
          label: "单位",
        },
        {
          value: 3,
          label: "部门",
        },
      ],
      options3: [],
      code: null,
      number: null,
      name: null,
      senior: false,
      value1: null,
      value2: null,
      value3: null,
      page: 1,
      size: 10,
      total: null,
      Id: 0,
      ParentId: 0,
      DepartmentId: 0,
      parameter: {
        Id: null,
        OrganizationType: 1,
        Name: "",
        NameEN: "",
        Code: "",
        Number: "",
        TreeId: null,
        TreeParentId: null,
        Abbreviation: null,
      },
      tree: [],
      props1: {
        value: "TreeId",
        label: "Name",
        children: "Children",
        checkStrictly: true,
      },
      treeObj: {},
      pickerOptionsStart: {
        disabledDate: (time) => {
          return time.getTime() < Date.parse("1899-12-31");
        },
      },
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      projectList: [],
      companyList: [],
      departmentList: [],
      loading: true,
    };
  },
  filters: {
    timeFormater(value) {
      value = value.substring(0, 10);
      return value;
    },
  },
  methods: {
    deleteItem(index) {
      this.messageList.splice(index, 1);
    },
    handleNoticeClose() {
      this.noticeDrawer = false;
    },

    handleClose() {
      this.dialogVisible = false;
      this.parameter = {
        Id: null,
        OrganizationType: 1,
        Name: "",
        NameEN: "",
        Code: "",
        Number: "",
        TreeId: null,
        TreeParentId: null,
        Abbreviation: null,
      };
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    newBuilt() {
      this.dialogVisible = true;
      this.dialogType = 1;
      this.roleName = "";
      this.enable = false;
    },
    edit(row) {
      this.dialogVisible = true;
      this.dialogType = 2;
      const multipleSelectionItem = row;

      this.parameter = {
        Id: multipleSelectionItem.OrganizationID,
        OrganizationType: multipleSelectionItem.OrganizationTypeID,
        Name: multipleSelectionItem.Name,
        NameEN: multipleSelectionItem.NameEN,
        Code: multipleSelectionItem.Code,
        Number: multipleSelectionItem.Number,
        Abbreviation: multipleSelectionItem.Abbreviation,
        TreeId: multipleSelectionItem.TreeId,
        TreeParentId: null,
      };
      switch (multipleSelectionItem.OrganizationTypeID) {
        case 1:
          this.options3 = this.projectList;
          break;
        case 2:
          this.options3 = this.companyList;
          break;
        case 3:
          this.options3 = this.departmentList;
          break;
      }

      if (multipleSelectionItem.TreeParentIds) {
        this.parameter.TreeParentId =
          multipleSelectionItem.TreeParentIds.split(",");
      }
    },

    doLayout() {
      this.$nextTick(() => {
        this.$refs.doLayout.doLayout();
      });
    },

    //获取部门列表
    getOrganizations(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        isRoot: true,
        code: this.code,
        name: this.name,
        number: this.number,
      };
      getOrganizations(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
          // this.doLayout();
        }
        this.loading = false;
      });
    },
    //获取子选项
    load(tree, treeNode, resolve) {
      this.treeObj[tree.TreeId] = { tree, treeNode, resolve };
      getOrganizationsChildren({
        treeId: tree.TreeId,
      }).then((res) => {
        if (res.status === 200) {
          if (res.response.length === 0) {
            this.resetLazyTree();
          }
          resolve(res.response);
        }
      });
    },
    //添加编辑角色
    addUpRoles() {
      if (this.dialogType === 1) {
        const data = {
          Id: this.parameter.Id,
          OrganizationType: this.parameter.OrganizationType,
          TreeParentIds: this.parameter.TreeParentId,
        };
        //新建
        addOrganizations(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.handleClose();
            this.getTree1();
            this.getOrganizations();
            for (let i in this.treeObj) {
              const { tree, treeNode, resolve } = this.treeObj[i];
              this.load(tree, treeNode, resolve);
            }
          }
        });
      } else {
        const data = {
          TreeId: this.parameter.TreeId,
          TreeParentIds: this.parameter.TreeParentId,
        };
        let index = -1;

        if (data.TreeParentIds) {
          index = data.TreeParentIds.findIndex((item) => {
            return item === data.TreeId;
          });
        }
        if (index !== -1) {
          this.$message.error("上级组织机构不能为自己");
          return;
        }
        updateOrganizations(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.getOrganizations();
            this.getTree1();
            this.handleClose();
            for (let i in this.treeObj) {
              const { tree, treeNode, resolve } = this.treeObj[i];
              this.load(tree, treeNode, resolve);
            }
          }
        });
      }
    },
    resetLazyTree() {
      this.$set(this.$refs.doLayout.store.states, "lazyTreeNodeMap", {});
    },
    getTree1() {
      getOrganizationsTree().then((res) => {
        if (res.status === 200) {
          this.tree = res.response;
          this.tree.forEach((item, index, Array) => {
            if (item.Children) {
              item.Children.forEach((cItem, cIndex, cArray) => {
                cItem.Children = null;
                cArray[cIndex] = cItem;
              });
              Array[index] = item;
            }
          });
        }
      });
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    changeOrganizationType() {
      switch (this.parameter.OrganizationType) {
        case 1:
          this.options3 = this.projectList;
          break;
        case 2:
          this.options3 = this.companyList;
          break;
        case 3:
          this.options3 = this.departmentList;
          break;
      }
      this.parameter.Id = null;
    },
    //删除项目部
    deleteOrganizations(row) {
      let data = { key: "" };
      data.key = row.TreeId;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteOrganizations(data).then((res) => {
          if (res.status === 200) {
            for (let i in this.treeObj) {
              const { tree, treeNode, resolve } = this.treeObj[i];
              this.load(tree, treeNode, resolve);
            }
            this.$nextTick(() => {
              this.$message.success("删除成功");
            });
          }
        });
      });
    },
  },

  async created() {
    await getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
        this.options3 = this.projectList;
      }
    });
    await getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    await getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    this.getOrganizations();
    this.getTree1();
    this.getButtonPower();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 24px;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 65px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
    }
  }
  .dialog-main {
    border-bottom: 2px solid #e6e6e6;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 340px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
          margin-right: 10px;
        }
        .el-input {
          width: 250px;
        }
        .el-select {
          width: 250px;
        }
      }
      .single {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;
        .name {
          flex-shrink: 0;
          width: 80px;
          margin-right: 10px;
        }
        .el-cascader {
          width: 100%;
        }
      }
    }
  }
  .dialog-footer {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<style scoped lang='scss'>
// el-table表格对齐
.el-table ::v-deep .el-table__row:not([class*="el-table__row--level-"]) {
  td:nth-child(2) {
    padding-left: 24px !important; //一级数据无Child缩进
  }
}
.el-table ::v-deep .el-table__placeholder {
  margin-left: 3px; //子节点无Child缩进
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 2px solid #e6e6e6;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> .el-table__placeholder {
  width: 23px;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
</style>
