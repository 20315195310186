//流程管理
export const tableData = [
  {
    procedure_name: "部门+角色按条件审核",
    table_name: "SellOrder",
    function_menu: "订单管理",
    weight: 100,
    isEdited: false,
    notes: "部门、角色",
    founder: "超级管理员",
    creation_time: "2023-05-13 16:31:42",
    modification_time: "2023-05-13 16:49:16",
  },
  {
    procedure_name: "退货单数量>10流程",
    table_name: "SellOrder",
    function_menu: "销售订单",
    weight: 500,
    isEdited: true,
    notes: "",
    founder: "超级管理员",
    creation_time: "2023-05-06 19:58:08",
    modification_time: "2023-10-17 16:01:43",
  },
  {
    procedure_name: "测试流程",
    table_name: "SellOrder",
    function_menu: "销售订单",
    weight: 50,
    isEdited: false,
    notes: "",
    founder: "超级管理员",
    creation_time: "2023-05-06 16:30:30",
    modification_time: "2023-11-08 18:24:16",
  },
];
// 我的审批
export const examine = [
  {
    procedure_name: "部门+角色按条件审核",
    table_name: "SellOrder",
    function_menu: "订单管理",
    processId: 'b9cv1h35jg',
    processName: '用户审批>=5000',
    state: 1,
    founder: "超级管理员",
    creation_time: "2023-05-13 16:31:42",
  },
  {
    procedure_name: "退货单数量>10流程",
    table_name: "SellOrder",
    function_menu: "销售订单",
    processId: 'b9cv1h35jg',
    processName: '用户审批>=5000',
    state: 2,
    founder: "超级管理员",
    creation_time: "2023-05-06 19:58:08",
  },
  {
    procedure_name: "测试流程",
    table_name: "SellOrder",
    function_menu: "销售订单",
    processId: 'b9cv1h35jg',
    processName: '用户审批>=5000',
    state: 3,
    founder: "超级管理员",
    creation_time: "2023-05-06 16:30:30",
  },
];

//我的审批，审核记录
export const auditRecords  = [
  {
    procedure_name: "部门+角色按条件审核",
    table_name: "SellOrder",
    function_menu: "订单管理",
    processId: 'b9cv1h35jg',
    processName: '用户审批>=5000',
    state: 1,
    founder: "超级管理员",
    creation_time: "2023-05-13 16:31:42",
    notes:'[超级管理员]提交了数据'
  },
  {
    procedure_name: "退货单数量>10流程",
    table_name: "SellOrder",
    function_menu: "销售订单",
    processId: 'b9cv1h35jg',
    processName: '用户审批>=5000',
    state: 2,
    founder: "超级管理员",
    creation_time: "2023-05-06 19:58:08",
    notes:''
  },
  {
    procedure_name: "测试流程",
    table_name: "SellOrder",
    function_menu: "销售订单",
    processId: 'b9cv1h35jg',
    processName: '用户审批>=5000',
    state: 3,
    founder: "超级管理员",
    creation_time: "2023-05-06 16:30:30",
    notes:''
  },
];
